
import { Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { buildGetFetch } from "../../../services/base";
import { Payment } from "../../../types/payment";
import { Loader } from "../../shared/Loader";

const PaymentSuccess: FC<{}> = () => {

    const {paymentId, examId} = useParams<{paymentId: string, examId: string}>();
    const [payment, setPayment] = useState<Payment>();
    const [loading, setLoading] = useState<boolean>(true);
    const history=useHistory();    

    useEffect(() => {
        const [fetch] = buildGetFetch<Payment>(`/exam/${examId}/payment/success?codTrans=${paymentId}`, {}, setPayment, setLoading);
        fetch();
    }, [paymentId, examId]);

    const gotoBody = useCallback(() => {
        history.push("/app/exams/" + examId + "/body");
    },[examId]);

    if(loading){
        return <div>
            <Loader/>
        </div>
    }

    return (<div>
            {payment && <Fragment>
                <Fragment>
                    <Alert color="success">
                        <p>Servizio attivato, puoi procedere a caricare immagini</p>
                        <Button color="primary" onClick={gotoBody}>Carica immagini</Button>
                    </Alert>
                </Fragment> 
            </Fragment>}
        </div>
    )
};

export {PaymentSuccess}