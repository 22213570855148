
import { Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { buildGetFetch } from "../../../services/base";
import { Payment } from "../../../types/payment";
import { Loader } from "../../shared/Loader";

const PaymentRejected: FC<{}> = () => {

    const {paymentId, examId} = useParams<{paymentId: string, examId: string}>();
    const [payment, setPayment] = useState<Payment>();
    const [loading, setLoading] = useState<boolean>(true);
    const history=useHistory();    

    useEffect(() => {
        const [fetch] = buildGetFetch<Payment>(`/exam/${examId}/payment/reject?codTrans=${paymentId}`, {}, setPayment, setLoading);
        fetch();
    }, [paymentId, examId]);

    const gotoPay = useCallback(() => {
        history.push("/app/exams/" + examId + "/payment");
    },[examId]);

    if(loading){
        return <div>
            <Loader/>
        </div>
    }

    return (<div>
            {payment && <Fragment>
                <Fragment>
                    <Alert color="error">
                        <p>Si è verificato un errore nella transazione di pagamento</p>
                        <Button color="secondary" onClick={gotoPay}>Riprova</Button>
                    </Alert>
                </Fragment> 
            </Fragment>}
        </div>
    )
};

export {PaymentRejected}