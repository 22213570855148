
import { Button, CircularProgress, Typography } from "@material-ui/core";
import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { buildPostFetch } from "../../../services/base";

const amount = 10;

interface PreparePaymentResponse {
    transaction_id: string;
    params: {
        alias: string;
        importo: number;
        importoDaVisualizzare: string;
        slotSize: number;
        divisa: string;
        codTrans: string;
        url: string;
        url_back: string;
        mac: string
    };
    url: string;
}

const NewPayment: FC<{}> = () => {

    let { examId } = useParams<{examId: any}>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [formData, setFormData] = useState<PreparePaymentResponse>();
    
    useEffect(() => {
        const pars:any={
            baseReturnUrl: document.location.protocol + "//" + document.location.host
        };
        const [fetch] = buildPostFetch<PreparePaymentResponse>(`/exam/${examId}/payment/pay`, pars);
        fetch()
            .then((data) => {
                if(data === null){
                    setError(true)
                } else {
                    setFormData(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [examId]);

    const formParamList = useMemo(() => {
        if(formData == null){
            return [];
        }
        return Object.keys(formData.params).map((key) => {
            return <input name={key} value={formData.params[key]} type="hidden" key={`hidden_form_${key}`}/>
        })
    }, [formData]);

    if(error){
        return <div>Errore nell'inizializzare i servizi di pagamento</div>
    }

    if(loading){
        return (
            <div className="loading">
                <CircularProgress color="secondary" />
            </div>
        );
    }

    return (
        <div>
            <Typography variant="body2" color="secondary">
                <b><u>Attivazione servizio a pagamento</u></b>
            </Typography>
            <Typography variant="body2" style={{marginTop:'1rem'}}>
                Confermando l'operazione attiverai la possibilità di inviare al 
                medico nuove immagini da esaminare.<br/><br/>
                L'attivazione di questo servizio 
                abilita il caricamento di <b>{formData.params.slotSize} nuove immagini</b> al costo totale di <b>{formData.params.importoDaVisualizzare}&euro;</b>.
            </Typography>
            {
                formData && <Fragment>
                    <form action={formData.url} method="post" style={{marginTop:'1rem'}}>
                        {formParamList}
                        {formParamList.length > 0 && 
                        <Button type="submit" variant="contained" color="secondary" style={{marginLeft:'0'}}>Attiva</Button>}
                    </form>
                </Fragment>
            }
        </div>
    )
};

export {NewPayment}