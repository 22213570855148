import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress, List, ListItemAvatar, Avatar, ListItemText, ListItem, Divider, Fab, Button, TextareaAutosize, TextField } from '@material-ui/core';
import { Back } from '../../shared/Back';
import { buildGetFetch, buildPostFetch } from '../../../services/base';
import { Alert } from '@material-ui/lab';
import { formatTS } from '../../../utils/dateutils';
import { Cancel, LocalHospital, Person, Send, Sms } from '@material-ui/icons';
import { Auth } from '../../../services/auth';


const Chat = () => {
    let { examId } = useParams<{examId: any}>();
    const messageList = useRef<HTMLDivElement>(null);
    const messageInput = useRef<HTMLTextAreaElement>(null);
    const [loading,setLoading] = useState<boolean>(true);
    const [messages,setMessages] = useState<any>();
    const [insertingMessage,setInsertingMessage] = useState<boolean>(false);
    const [isSubmitting,setSubmitting] = useState<boolean>(false);

    let tout:any=null;

    const scrollBottom=() => {
        setTimeout(() => {
            if(messageList.current) {
                window.scrollTo(0,messageList.current.scrollHeight);
            }
        },50);
    }

    const loadMessages=() => {
        const [fetch, cancel] = buildGetFetch<Array<any>>(`/exam/${examId}/chat`, {}, 
            (r) => { 
                setMessages(r);     
                scrollBottom();
            },setLoading);
        fetch();
        return () => {
            cancel();
        }
    }

    useEffect(() => {
        loadMessages();    
        setTimeout(() => {
            setupRefresh();
        },5000);
        return function cleanup() {
            if(tout) {
                console.log("cancelling chat timeout");
                clearTimeout(tout);
            }
        };
    }, [examId]);    

    const setupRefresh=() => {
        tout=setTimeout(() => {
            loadMessages();
            setupRefresh();
        },5000);
    }    

    const saveMessage = useCallback(() => {
        setSubmitting(true);
        const msg = messageInput.current?.value;
        if (msg && msg.trim() !== "") {
            const [post] = buildPostFetch<any>(`/exam/${examId}/chat`, { text: msg }, { toastError: true }, () => { }, setSubmitting);
            post().then((m) => {                
                if (m !== null) {                    
                    loadMessages();
                    //@ts-ignore
                    messageInput.current.value = '';
                    setInsertingMessage(false);
                }
            })
        } else {
            setSubmitting(false);
        }
    }, [loadMessages]); 
    
    const showMessageForm=useCallback(() => {
        setInsertingMessage(true);        
        scrollBottom();
    },[]);

    const hideMessageForm=useCallback(() => {
        setInsertingMessage(false);
    },[]);

    let listItems=[];
    if(messages) {
        listItems=messages.map(m => {
            let css="other";
            if(m.user_id && Auth.user().id==m.user_id) css="mine";
            else if(m.patient_id && Auth.user().id==m.patient_id) css="mine";
            return (
                <Fragment key={m.id}>
                    <ListItem alignItems="flex-start" className={"chat-message " + css}>
                        {/*
                        <ListItemAvatar>
                            <Fragment>
                                {m.user_id &&
                                    <Avatar alt="Medico" className="medic"><LocalHospital/></Avatar>
                                }
                                {m.patient_id &&
                                    <Avatar alt="Paziente" className="patient"><Person/></Avatar>
                                }
                            </Fragment>
                        </ListItemAvatar>
                        */}
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" color="textPrimary">
                                        {m.user_id && Auth.user().id==m.user_id &&
                                            <span className="who">Tu</span>
                                        }
                                        {m.user_id && Auth.user().id!=m.user_id &&
                                            <span className="who">Dott. {m.user.surname} {m.user.name}</span>
                                        }
                                        {m.patient_id && Auth.user().id==m.patient_id &&
                                            <span className="who">Tu</span>
                                        }
                                        {m.patient_id && Auth.user().id!=m.patient_id &&
                                            <span className="who">{m.patient.surname} {m.patient.name}</span>
                                        }
                                    </Typography>
                                    {m.text}
                                    <span className="date">
                                        {formatTS(m.insert_ts)}
                                    </span>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    {/*<Divider variant="inset" component="li" />*/}
                </Fragment>
            );
        });
    }
    
    return (
        <Fragment>
            <Back/>
            { loading &&
                <div className="loading">
                    <CircularProgress color="secondary" />
                </div>
            }
            { !loading &&
                <div style={{marginLeft:'-12px',marginRight:'-12px'}} className="chat-messages" >

                    { messages.length===0 &&
                        <Alert severity="success">Nessun messaggio</Alert>
                    }
                    { messages.length>0 &&
                        <div ref={messageList}>
                            <List>
                                {listItems}
                            </List>
                        </div>
                    }

                    {!insertingMessage &&
                        <div className="chat-button">
                            <Fab color="secondary" onClick={showMessageForm}><Sms/></Fab>                                
                        </div>                    
                    }
                    { insertingMessage &&
                        <div className="chat-insert">
                            <form>
                                <table style={{width:'100%'}}>
                                    <tbody>
                                    <tr style={{verticalAlign:'top'}}>
                                        <td>
                                            <TextField inputRef={messageInput} rowsMax={7} style={{width:'100%'}} multiline name="message_text" disabled={isSubmitting} />
                                        </td>
                                        <td style={{width:'1px',paddingLeft:'1em'}}>
                                            <Fab color="primary" disabled={isSubmitting} onClick={saveMessage}>
                                                <Send/>
                                            </Fab><br/><br/>
                                            <Fab color="secondary" disabled={isSubmitting} onClick={hideMessageForm}>
                                                <Cancel/>
                                            </Fab>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    }
                </div>                
            }
        </Fragment>
    );
}


export {Chat}