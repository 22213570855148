
import { Divider, Grid, Typography, Box } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { buildGetFetch } from "../../../services/base";
import { PatientData } from "../../../types/patient";
import { formatDT, getAgeFromBirthDate } from "../../../utils/dateutils";
import { Loader } from "../../shared/Loader";

export const UserCenter: FC<{}> = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<PatientData>();

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch<PatientData>('/user', {}, setData, setLoading);
        fetch();
        return () => {
            cancel();
        }
    }, []);

    if (loading) {
        return (<Loader/>)
    }
    if (!data || !data.patient) {
        return (<Loader/>)
    }
    return (<div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" pb={1}>
                    <Typography variant="h6" color="secondary">Paziente</Typography>
                </Box>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" flexDirection="column">
                    <Box color="textSecondary" component="span">COGNOME</Box>
                    <Typography variant="h6">{data.patient.surname}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                    <Box color="textSecondary" component="span">NOME</Box>
                    <Typography variant="h6">{data.patient.name}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                    <Box color="textSecondary" component="span">SESSO</Box>
                    <Typography variant="h6">{data.patient.genre}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                    <Box color="textSecondary" component="span">CODICE FISCALE</Box>
                    <Typography variant="h6">{data.patient.taxcode}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                    <Box color="textSecondary" component="span">DATA DI NASCITA</Box>
                    <Typography variant="h6">{formatDT(data.patient.date_of_birth)}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                    <Box color="textSecondary" component="span">ETA'</Box>
                    <Typography variant="h6">{getAgeFromBirthDate(data.patient.date_of_birth)}</Typography>
                </Box>
            </Grid>            
        </Grid>
    </div>)
}

