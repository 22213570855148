
import React, { useCallback, useState } from "react";
import { Back } from "../../shared/Back";
import { buildPostFetch } from "../../../services/base";
import { useParams } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

const ContactForm= () => {
    const [data,setData]=useState<any>({});
    const [sending,setSending]=useState<boolean>(false);
    const [sent,setSent]=useState<boolean>(false);
    const { examId } = useParams<{examId: any}>();
    
    const sendMessage = useCallback(() => {
        let pars=data;
        if(!pars || !pars.message) return;
        setSending(true)
        const [fetch] = buildPostFetch<any>(`/exam/${examId}/support`, pars);
        fetch()
            .then((data) => {
                if(data != null){
                    setSent(true)
                    setSending(false)
                }
            })
            .finally(() => {
                setSending(false);
            });
    },[setSent,data])


    return (
        <div className="contactForm">
            <Back/>
            { sending &&
                <div className="loading" style={{marginTop:'10px'}}>
                    <CircularProgress color="secondary" />
                </div>
            }
            {!sending && !sent &&
                <form>
                    <div className="form-group row">
                        <label htmlFor="request_message" className="col-md-2 col-form-label text-md-right">Richiesta:</label>
                        <div className="col-md-6">
                            <textarea id="request_message" rows={12} className="form-control " name="request_message" required={true} value={data.message} 
                                    onChange={(v) => { setData({ message: v.target.value }) }}></textarea>
                        </div>
                    </div>

                    <div className="form-group row mb-0">
                        <div className="col-md-6 offset-md-2">
                            <button type="button" className="btn btn-primary" onClick={sendMessage}>Invia richiesta</button>
                        </div>
                    </div>
                </form>
            }
            {!sending && sent &&
                <div style={{marginTop:"10px"}}>
                    <Alert severity="success">Richiesta inviata</Alert>
                </div>
            }
        </div>
    );

}

export { ContactForm };

