
import React, { Fragment, useCallback, useMemo } from 'react';
import { Grid, Divider, List, ListItem, ListItemText, Typography, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AlertTitle } from '@material-ui/lab';
import { useGetApi } from '../../../utils/hooks';
import { formatDT } from '../../../utils/dateutils';
import { useHistory } from 'react-router-dom';
import { Exam } from '../../../types/exam';
import { Loader } from '../../shared/Loader';
import { fakeId } from '../../../utils/faker';
import { Back } from '../../shared/Back';
import { Auth } from '../../../services/auth';

const ExamsList = () => {
    const [error, loading, result] = useGetApi<Array<Exam>>('/exam');
    const history = useHistory();
    const consultation=Auth.consultation();

    const goToExam = useCallback((id: number) => {
        history.push(`/app/exams/${id}`);
    }, [history]);

    const showDoctor = useCallback((e:any) => {
        if(e.medical_reports_exam && e.medical_reports_exam.creator) {
            return "Dott. " + e.medical_reports_exam.creator.surname + " " + e.medical_reports_exam.creator.name;
        } else if(e.owner) {
            return "Dott. " + e.owner.surname + " " + e.owner.name;
        } else {
            return "";
        }
    },[]);

    if (loading) {
        return (<Loader/>)
    }
    if (error) {
        return <Alert>
            <AlertTitle><span role="img" aria-label="problema">😡</span></AlertTitle>
            E' stato riscontrato un errore, riprova più tardi
        </Alert>
    }
    return (<div>
        <Back goBackTo={() => {history.replace("/app")}}/>
        <Grid container color="secondary">
            <Grid item xs={12}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" pb={1} className="exam-list-title">
                    <Typography variant="h6"color="secondary">Le tue visite</Typography>
                </Box>
                <Divider />
            </Grid>
            <List aria-label="main-list" id="exam-list" style={{ width: '100%' }}>
                {result.map((e) => {                    
                    return <Fragment key={'exam_' + e.id}>
                        <ListItem alignItems="center" button onClick={
                            () => {
                                goToExam(e.id);
                            }
                        }>
                            <ListItemText primary={<u>{e.title}</u>} secondary={
                                <Fragment>
                                    {formatDT(e.insert_ts)} &mdash; {showDoctor(e)} <br/>
                                    Num. accettazione: <b>{fakeId(e.id)}</b>
                                    <div style={{float:'right',marginTop:'4px'}}>
                                        {e.medical_reports_exam &&
                                            <Alert variant="filled" severity="success">Parere medico disponibile</Alert>
                                        }
                                        {!e.medical_reports_exam && (!e.medical_media_exam || e.medical_media_exam.length==0) &&                                        
                                            <Alert variant="filled" severity="warning">Carica le immagini per avere un parere</Alert>
                                        }
                                        {!e.medical_reports_exam && e.medical_media_exam && e.medical_media_exam.length>0 &&                                   
                                            <Alert variant="filled" severity="warning">In attesa di parere medico</Alert>
                                        }
                                    </div>
                                </Fragment>
                            }>
                            </ListItemText>
                        </ListItem>
                        <Divider component="li" />
                    </Fragment>
                })}
            </List>
        </Grid>
    </div>
    )
};

export { ExamsList }