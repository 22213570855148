
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { NewPayment } from './NewPayment';
import { PaymentDetail } from './PaymentDetail';
import { Typography } from '@material-ui/core';
import { Back } from '../../shared/Back';

function PaymentCenter() {
    let { path } = useRouteMatch();
    console.log(path);
    return (
        <React.Fragment>
            <Back/>
            <Switch>
                <Route exact path={path}>
                    <NewPayment />
                </Route>
                <Route path={`${path}/:paymentId`}>
                    <PaymentDetail/>
                </Route>
            </Switch>
        </React.Fragment>
    );
}

export default PaymentCenter;

