
import { Typography } from "@material-ui/core";
import React, { FC, Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { buildGetFetch } from "../../../services/base";
import { Payment } from "../../../types/payment";
import { Loader } from "../../shared/Loader";

const PaymentDetail: FC<{}> = () => {

    const {paymentId, examId} = useParams<{paymentId: string, examId: string}>();
    const [payment, setPayment] = useState<Payment>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const [fetch] = buildGetFetch<Payment>(`/exam/${examId}/payment/${paymentId}`, {}, setPayment, setLoading);
        fetch();
    }, [paymentId, examId]);

    if(loading){
        return <div>
            <Loader/>
        </div>
    }

    return (<div>
            {payment && <Fragment>
                <Typography variant="subtitle1" gutterBottom>Risultato pagamento</Typography>
                {payment.status === 'request' && <div>
                    <Fragment>
                        <div>
                            <p>Pagamento in corso</p>
                        </div>
                    </Fragment> 
                </div>}
                {payment.status === 'success' && <div>
                    <Fragment>
                        <div>
                            <p>Pagamento confermato, procedi a far foto</p>
                        </div>
                    </Fragment> 
                </div>}
                {payment.status === 'reject' && <div>
                    <Fragment>
                        <div>
                            <p>Il pagamento è stato annullato/rifiutato</p>
                            <Link to={'/app/payment'}>Riprova pagamento</Link>
                        </div>
                    </Fragment> 
                </div>}
            </Fragment>}
        </div>
    )
};

export {PaymentDetail}