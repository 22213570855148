
import React, { useCallback, Fragment, useEffect, useState } from 'react';
import { Button } from '../../shared/Button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Typography, Grid, Box, CircularProgress } from '@material-ui/core';
import { Back } from '../../shared/Back';
import { buildGetFetch } from '../../../services/base';
import { Exam, ExamCapability } from '../../../types/exam';
import { formatDT } from '../../../utils/dateutils';
import { useGetApi } from '../../../utils/hooks';
import { fakeId } from '../../../utils/faker';
import { Auth } from '../../../services/auth';
import { Alert } from '@material-ui/lab';


const Examination = () => {
    let { examId } = useParams<{examId: any}>();
    let history = useHistory();
    let location = useLocation();
    
    const [exam, setExam] = useState<Exam>(null);
    const [allExams, setAllExams] = useState<Array<Exam>>(null);
    const [caps, setCaps] = useState<Array<ExamCapability>>(null);
    const [loading,setLoading] = useState<boolean>(true);
    const [examsLoading,setExamsLoading] = useState<boolean>(true);

    const isConsultation:boolean = false; // disabilitato il supporto al consulto //examId==Auth.consultation().exam.id;

    const determineExam = (exams:Array<Exam>) => {
        let filteredExams:Array<Exam>=exams.filter(e => e.id==examId);
        setExam(filteredExams[0]);
    }

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch<Array<Exam>>(`/exam`, {}, determineExam,setExamsLoading);
        fetch();        
        return () => {
            cancel();
        }
    }, [examId]);

    useEffect(() => {
        /**
         * We need capability for this exam
         */
        const [fetch, cancel] = buildGetFetch<Array<ExamCapability>>(`/exam/${examId}/capability`, {}, setCaps,setLoading);
        fetch();
        return () => {
            cancel();
        }
    }, [examId]);

    const canPerform = useCallback((code: number) => {
        return caps.findIndex((c) => {return c.capability_type === code}) >= 0;
    }, [caps]);

    const goToBody = useCallback((path: string) => {
        history.push(`${location.pathname}/${path}`);
    }, [history,location]);

    const showDoctor = useCallback((e:any) => {
        if(e.medical_reports_exam && e.medical_reports_exam.creator) {
            return "Dott. " + e.medical_reports_exam.creator.surname + " " + e.medical_reports_exam.creator.name;
        } else if(e.owner) {
            return "Dott. " + e.owner.surname + " " + e.owner.name;
        } else {
            return "";
        }
    },[]);

    const goBack = useCallback(() => {
        if(isConsultation)
            history.replace("/app");
        else
            history.replace("/app/exams")
    },[]);

    return (
        <div style={{marginTop:'16px'}}>
            <Back goBackTo={goBack}/>
            { (loading || examsLoading) &&
                <div className="loading">
                    <CircularProgress color="secondary" />
                </div>
            }
            { caps &&
                <Grid container spacing={2}>
                    { !isConsultation &&
                        <Grid item xs={12}>
                            { exam &&
                                <div className="exam-ids">
                                    Num. Accettazione: <b>{fakeId(exam.id)}</b><br/>
                                    {formatDT(exam.insert_ts)} &mdash; {showDoctor(exam)} 
                                    <hr/>
                                    <i>{exam.title}</i>
                                </div>
                            }
                        </Grid>
                    }

                    { exam && !exam.medical_reports_exam &&
                        <div style={{margin:'8px'}}>
                            <Alert variant="filled" severity="warning">
                                Il parere medico non è ancora disponibile. Lo sarà entro 48 ore dal caricamento delle immagini.
                                Controlla piu’ tardi (riceverai comunque una e mail al momento della pubblicazione).
                            </Alert>
                        </div>
                    }

                    <Grid item xs={6}>
                        <Box borderRadius={'3px'} color="primary.contrastText"style={{height: '100px'}}>
                            <Button size="fluid" onClick={() => {goToBody('anamnesis')}}>
                                <Typography>Anamnesi</Typography>
                                <i className="fa fa-book-medical"/>
                            </Button>
                        </Box>
                    </Grid>

                    {canPerform(200) && <Grid item xs={6}>
                        <Box borderRadius={'3px'} color="primary.contrastText" style={{height: '100px'}}>
                            <Button size="fluid" onClick={() => {goToBody('body')}}>
                                <Typography>Immagini</Typography>
                                <i className="fa fa-camera"/>
                            </Button>
                        </Box>
                    </Grid>}
                    {canPerform(150) && <Grid item xs={6}>
                        <Box borderRadius={'3px'} color="primary.contrastText"style={{height: '100px'}}>
                            <Button size="fluid" onClick={() => {goToBody('report')}}>
                                <Typography>Parere medico</Typography>
                                <i className="fa fa-file"/>
                            </Button>
                        </Box>
                    </Grid>}
                    {canPerform(300) && <Grid item xs={6}>
                        <Box borderRadius={'3px'} color="primary.contrastText"style={{height: '100px'}}>
                            <Button size="fluid" onClick={() => {goToBody('chat')}}>
                                <Typography>Comunica con il medico</Typography>
                                <i className="fa fa-comments"/>
                            </Button>
                        </Box>
                    </Grid>}

                    {!canPerform(300) && <Grid item xs={6}>
                        <Box borderRadius={'3px'} color="primary.contrastText"style={{height: '100px'}}>
                            <Button size="fluid" onClick={() => {goToBody('contact')}}>
                                <Typography>Contatta l'assistenza</Typography>
                                <i className="fa fa-comments"/>
                            </Button>
                        </Box>
                    </Grid>}

                    { /*
                    <Grid item xs={6}>
                        <Box borderRadius={'3px'} color="primary.contrastText"style={{height: '100px'}}>
                                <Button size="fluid" onClick={() => {goToBody('payment')}}>
                                    <Typography>Pagamenti</Typography>
                                    <i className="fa fa-euro-sign"/>
                                </Button>
                            </Box>
                    </Grid> */}
                </Grid>
            }
        </div>
    )
};

export {Examination}